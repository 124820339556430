<template>
  <div class="">
    <!-- 属性 -->


      <van-cell
        v-for="(item, index) in infoList"
      :key="index"
    v-show="!item.hide"
    :title="item.label" :value="item.value" />


    <!--  -->
  </div>
</template>

<script>
const moment = window.moment;
import { concealCarPlateNumb, concealPhoneNumb } from '@/utils/tools';

export default {
  components: {},
  props: {
    form: Object,
  },
  data() {
    return {
      infoList:[
        {
          label: '申请进度',
          value: this.form.statusStr,
        },
        {
          label: '经纪人',
          value: this.form.brokerName,
        },
        {
          label: '申请人',
          value: this.form.name,
        },
        {
          label: '手机号码',
          value: concealPhoneNumb(this.form.mobile),
        },
        {
          label: '产品名称',
          value: this.form.productName,
        },
        {
          label: '车牌号',
          value: concealCarPlateNumb(this.form.vehiclePlateNo),
        },
        {
          label: '车辆型号',
          value: this.form.vehicleFullName,
        },
        {
          label: '所在城市',
          value: `${this.form.provinceName || ''}-${this.form.cityName || ''}`,
        },
        {
          label: '申请金额',
          value: this.form.applyAmount,
        },
        {
          label: '申请期限',
          value: this.form.applyTermStr,
        },

        {
          label: '成交金额',
          value: this.form.putOutAmt,
        },
        {
          label: '成交日期',
          value: this.form.putOutDate
            ? moment(this.form.putOutDate).format('YYYY-MM-DD')
            : '-',
        },

        {
          hide: this.form.status != '00',
          label: '拒绝原因',
          value: this.form.rejectReason,
        },

      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {
    moment,
    concealPhoneNumb,
    concealCarPlateNumb,

    //
  },
};
</script>
